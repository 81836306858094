import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  WebhookStatusSet: {
    id: 'Webhook.WebhookStatusSet',
    defaultMessage: 'Webhook status set.',
  },
  ErrorOccured: {
    id: 'Webhook.ErrorOccured',
    defaultMessage: 'Error occured!',
  },
  SureDelete: {
    id: 'Webhook.SureDelete',
    defaultMessage: 'Are you sure you want to delete this web hook?',
  },
  WebhookDeleted: {
    id: 'Webhook.WebhookDeleted',
    defaultMessage: 'Webhook deleted.',
  },
  Payload: {
    id: 'Webhook.Payload',
    defaultMessage: 'Payload',
  },
  HttpResponse: {
    id: 'Webhook.HttpResponse',
    defaultMessage: 'Http Response',
  },
  CreatedAt: {
    id: 'Webhook.CreatedAt',
    defaultMessage: 'Created At',
  },
  PublisherId: {
    id: 'Webhook.PublisherId',
    defaultMessage: 'Publisher ID',
  },
  UserId: {
    id: 'Webhook.UserId',
    defaultMessage: 'User ID',
  },
  ViewPayload: {
    id: 'Webhook.ViewPayload',
    defaultMessage: 'View Payload',
  },
  Close: {
    id: 'Webhook.Close',
    defaultMessage: 'Close',
  },
  Type: {
    id: 'Webhook.Type',
    defaultMessage: 'Type',
  },
  Search: {
    id: 'Webhook.Search',
    defaultMessage: 'Search',
  },
  New: {
    id: 'Webhook.New',
    defaultMessage: 'New Webhook',
  },
  WebhookSaved: {
    id: 'Webhook.WebhookSaved',
    defaultMessage: 'Webhook saved.',
  },
  ErrorSaving: {
    id: 'Webhook.ErrorSaving',
    defaultMessage: 'Error occured while saving!',
  },
  ErrorNameEmpty: {
    id: 'Webhook.ErrorNameEmpty',
    defaultMessage: 'Name can not be empty.',
  },
  ErrorContentEmpty: {
    id: 'Webhook.ErrorContentEmpty',
    defaultMessage: 'Please select a content.',
  },
  EnterValidUrl: {
    id: 'Webhook.EnterValidUrl',
    defaultMessage: 'Please enter a valid url!',
  },
  ErrorIncompleteDelay: {
    id: 'Webhook.ErrorIncompleteDelay',
    defaultMessage:
      'Incomplete delay can not be smaller than delay and 60 seconds.',
  },
  EditWebhook: {
    id: 'Webhook.EditWebhook',
    defaultMessage: 'Edit Webhook',
  },
  CreateNewWebHook: {
    id: 'Webhook.CreateNewWebHook',
    defaultMessage: 'Create New Web Hook',
  },
  ClosePanel: {
    id: 'Webhook.ClosePanel',
    defaultMessage: 'Close Panel',
  },
  Name: {
    id: 'Webhook.Name',
    defaultMessage: 'Name',
  },
  Delay: {
    id: 'Webhook.Delay',
    defaultMessage: 'Delay',
  },
  SendAnswers: {
    id: 'Webhook.SendAnswers',
    defaultMessage: 'Send Answers',
  },
  SendAllData: {
    id: 'Webhook.SendAllData',
    defaultMessage: 'Send all the votes with the data.',
  },
  SendLeads: {
    id: 'Webhook.SendLeads',
    defaultMessage: 'Send Leads',
  },
  SendAllLeads: {
    id: 'Webhook.SendAllLeads',
    defaultMessage: 'Send all user input leads with the data.',
  },
  SendIncomplete: {
    id: 'Webhook.SendIncomplete',
    defaultMessage: 'Send Incomplete',
  },
  Triggers: {
    id: 'Webhook.Triggers',
    defaultMessage: ' Triggers webhook even if user did not see the end result',
  },
  IncompleteDelay: {
    id: 'Webhook.IncompleteDelay',
    defaultMessage: 'Incomplete Delay',
  },
  Cancel: {
    id: 'Webhook.Cancel',
    defaultMessage: 'Cancel',
  },
  Save: {
    id: 'Webhook.Save',
    defaultMessage: 'Save',
  },
  ContentId: {
    id: 'Webhook.ContentId',
    defaultMessage: 'Content ID',
  },
  Active: {
    id: 'Webhook.Active',
    defaultMessage: 'Active',
  },
  Health: {
    id: 'Webhook.Health',
    defaultMessage: 'Health',
  },
  WebhookDesc: {
    id: 'Webhook.WebhookDesc',
    defaultMessage:
      'Webhooks are events that trigger an action. In most cases, they are used for communication between systems. This is the simplest way of getting an alert when something happens in another system.',
  },
  SendDesc: {
    id: 'Webhook.SendDesc',
    defaultMessage:
      'Poltio will send a POST request with a JSON body to your URL with the following format.',
  },
  To: {
    id: 'Webhook.To',
    defaultMessage: 'To',
  },
  Method: {
    id: 'Webhook.Method',
    defaultMessage: 'Method',
  },
  Message: {
    id: 'Webhook.Message',
    defaultMessage: 'Message',
  },
  DelayDesc: {
    id: 'Webhook.DelayDesc',
    defaultMessage:
      'Delay in seconds between event trigger (set finish) and webhook fire in seconds. This will be usefull if you have leads on the result page and you want to give your visitors some time to get the lead data with your webhook.',
  },
  IncompleteDesc: {
    id: 'Webhook.IncompleteDesc',
    defaultMessage:
      'Delay in seconds between set start and webhook fire. For example if you set this field as 120, Poltio will send votes up till 120 seconds after your visitor starts the set. If that visitor will complete the set after 120 seconds, you will also recieve another webhook event for that.',
  },
  SelectContent: {
    id: 'Webhook.SelectContent',
    defaultMessage: 'Select your content for WebHook',
  },
  Duplicate: {
    id: 'Webhook.Duplicate',
    defaultMessage: 'Duplicate',
  },
  DuplicateOf: {
    id: 'Webhook.DuplicateOf',
    defaultMessage: 'Duplicate Of ',
  },
  Edit: {
    id: 'Webhook.Edit',
    defaultMessage: 'Edit',
  },
  Logs: {
    id: 'Webhook.Logs',
    defaultMessage: 'Logs',
  },
  DuplicateWebhook: {
    id: 'Webhook.DuplicateWebhook',
    defaultMessage: 'Duplicate Webhook',
  },
  Webhooks: {
    id: 'Webhook.Webhooks',
    defaultMessage: 'Webhooks',
  },
  WebhooksDesc: {
    id: 'Webhook.WebhooksDesc',
    defaultMessage:
      'Webhooks allows you to share widget actions realtime in json format to connect with other applications.',
  },
  MoreInfo: {
    id: 'Webhook.MoreInfo',
    defaultMessage: 'For More Information',
  },
  Docs: {
    id: 'Webhook.Docs',
    defaultMessage: 'Documentation',
  },
  AccountWide: {
    id: 'Webhook.AccountWide',
    defaultMessage: 'Account Wide',
  },
  AccountWideDesc: {
    id: 'Webhook.AccountWideDesc',
    defaultMessage:
      'If you check this, this webhook will work on all content in your account.',
  },
});
