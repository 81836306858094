import React from 'react';
import { ItemsListExpander } from '../Form/Dropdown/ItemList';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type SearchFieldItem = {
  id: string | number;
  name: string;
  selected?: boolean;
  disabled?: boolean;
};
type SearchFieldItemsRowRendererProps = {
  item: any;
};
type SearchFieldItemsRendererOptions = {
  selected: boolean;
  onSelectCallback?: (index: number, item: SearchFieldItem) => void;
  rowRenderer?: React.ElementType<SearchFieldItemsRowRendererProps>;
};
type Props = {
  data: SearchFieldItem[];
  containerRef: React.RefObject<HTMLDivElement>;
  loading: boolean;
  onSelectCallback?: (index: number, item: SearchFieldItem) => void;
  onCloseCallback?: () => void;
  rowRenderer?: React.ElementType;
  initialFocus?: React.MutableRefObject<any>;
  dialogProps?: {
    className?: string;
  };
};

function SearchFieldItemsRenderer(
  item: SearchFieldItem,
  index: number,
  array: any[],
  options: SearchFieldItemsRendererOptions
) {
  const { selected } = item;
  const Renderer = options?.rowRenderer;

  return (
    <li
      key={index}
      className="bg-white dark:bg-gray-800 dark:hover:bg-poltio-blue-500 dark:text-gray-200 list-none first:rounded-t-md last:rounded-b-md text-gray-900 cursor-pointer select-none relative hover:text-poltio-blue-100 hover:bg-poltio-blue-500"
    >
      <button
        className="w-full text-left h-full"
        onClick={(event) => {
          options?.onSelectCallback?.(index, item);
        }}
        disabled={item.disabled ?? false}
      >
        <span
          className={classNames(
            selected ? 'font-medium' : 'font-normal',
            'block truncate py-2 pl-4 pr-4'
          )}
        >
          {Renderer ? <Renderer item={item} /> : item.name}
        </span>
      </button>
    </li>
  );
}

const SearchFieldItems: React.FC<Props> = (props) => {
  const { loading } = props;

  if (loading) {
    // TODO: better loading animation
    return (
      <div className="animate-pulse h-1 border-none rounded-none bg-poltio-blue-500" />
    );
  }

  let data = props?.data ?? [];

  if (data.length <= 0) {
    data.push({
      id: '',
      name: 'No matches found!',
      disabled: true,
    });
  }

  const onSelectCallback = props?.onSelectCallback ?? (() => {});
  const onCloseCallback = props?.onCloseCallback ?? (() => {});

  return (
    <ItemsListExpander
      data={props.data ?? []}
      containerRef={props.containerRef}
      renderer={SearchFieldItemsRenderer}
      onSelectCallback={onSelectCallback}
      onCloseCallback={onCloseCallback}
      rowRenderer={props.rowRenderer}
      initialFocus={props?.initialFocus}
      dialogProps={props?.dialogProps}
      modal
    />
  );
};

export default SearchFieldItems;
