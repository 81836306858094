import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import LeadSearch from '../SearchField/LeadSearch';
import messages from './messages';
import CheckBoxInput from './Checkbox';

const _LeadInput = ({
  leadId,
  leadName,
  onSelect,
  onDelete,
  onNew,
  canPropagateToAnswers,
  intl: { formatMessage },
}) => {
  const [removeLeadFromAnswers, setRemoveLeadFromAnswers] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div className="flex flex-col dark:text-gray-200">
        {formatMessage(messages.AddLead)}
        <div className="flex items-center pt-1 pb-2 absolute w-3/4 mt-6">
          <div className="w-full">
            <LeadSearch
              leadId={leadId}
              onSelect={onSelect}
              leadName={leadName}
            />
          </div>
          <button
            className="poltio-btn-xs text-red-500 mx-1 p-2 rounded border hover:text-white hover:bg-red-500 focus:ring-red-500 focus:ring-offset-1"
            onClick={(event) => onDelete(event, removeLeadFromAnswers)}
          >
            <TrashIcon className="h-5" />
          </button>
          <button
            className="poltio-btn-xs text-poltio-blue mx-1 p-2 rounded border hover:text-white hover:bg-poltio-blue focus:ring-poltio-blue focus:ring-offset-1"
            onClick={onNew}
          >
            <PlusIcon className="h-5" />
          </button>
        </div>
      </div>
      {canPropagateToAnswers ? (
        <div className="flex items-center mt-14">
          <CheckBoxInput
            name="removeLeadFromAnswers"
            text={formatMessage(messages.RemoveFromAll)}
            checked={removeLeadFromAnswers}
            onChange={() => {
              setRemoveLeadFromAnswers((prev) => !prev);
            }}
          />
        </div>
      ) : null}
      <div className={classNames(canPropagateToAnswers ? '' : 'mt-14')}>
        <div className="text-xs py-1 mb-1 text-gray-400 dark:text-gray-300">
          {formatMessage(messages.LeadDesc)}
        </div>
      </div>
    </>
  );
};
const LeadInput = injectIntl(_LeadInput);

export { LeadInput };
export default LeadInput;
