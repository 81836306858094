import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { useDebouncedCallback } from 'use-debounce';

import { Combobox, Transition } from '@headlessui/react';
import SearchFieldItems from './SearchFieldItems';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';

let SearchField = (props, ref) => {
  let {
    data = undefined,
    defaultMessage = undefined,
    displayValue = '',
    fetchData = async () => {},
    onItemChange = () => {},
    loadInit = false,
    type = '',
    filterCallback = undefined,
    ...rest
  } = props;

  const containerRef = useRef(null);
  const inputFieldRef = useRef(null);

  const [query, setQuery] = useState('');

  // to prevent excessive fetching
  const [fetchTime, setFetchTime] = useState(0);
  const fetchDeltaMS = 20000; // time difference between two fetches in milliseconds

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [selected, setSelected] = useState({});
  const [contents, setContents] = useState([]);
  const [dirty, setDirty] = useState(false);

  const _fetchData = useCallback(async () => {
    const d = new Date().getTime() - fetchTime;
    if (d < fetchDeltaMS) {
      // has fetched within last 20 seconds, no need to refresh data
      return;
    }
    setLoading(true);

    await fetchData(dirty, setContents, setText);
    setFetchTime(new Date().getTime());

    setLoading(false);
    setDirty(false);
  }, [fetchTime, dirty, fetchData]);
  const debouncedFetchData = useDebouncedCallback(_fetchData, 1000, {
    leading: true,
    trailing: false,
  });
  useEffect(() => setText(displayValue), [displayValue]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!loadInit) {
      return;
    }
    if (displayValue !== '') {
      return;
    }

    debouncedFetchData();
  }, [debouncedFetchData, loadInit, displayValue, loading]);

  const _OnItemChange = (index, item) => {
    setSelected(item);
    onItemChange(item, setText);
  };

  function onQueryChange(event) {
    // setDirty(true); // TODO: figure out why this was implemented
    setQuery(event.target.value);
  }

  function onInputSelect() {
    debouncedFetchData();
  }

  let items = data ?? contents ?? [];
  const selectionIdx = items.findIndex((item) => item.id === selected.id);
  if (selectionIdx >= 0) {
    items[selectionIdx].selected = true;
  }
  if (items && filterCallback && query !== '') {
    items = items.filter((item) => filterCallback(item, query));
  }

  return (
    <div className="relative flex justify-between mt-1" ref={containerRef}>
      <Combobox value={selected} onChange={_OnItemChange}>
        <Combobox.Label className="block text-sm font-medium text-gray-700">
          {defaultMessage !== undefined ? (
            defaultMessage
          ) : (
            <FormattedMessage
              id="ContentCard.SearchEnter"
              defaultMessage="Search content and press enter"
            />
          )}
        </Combobox.Label>
        <div className="block w-full border-gray-300 shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm rounded-md">
          <Combobox.Input
            as={React.Fragment}
            onChange={onQueryChange}
            displayValue={() => text ?? ''}
            className="dark:bg-gray-800 dark:border-0 dark:text-gray-200"
          >
            <input
              onClick={onInputSelect}
              // onFocus={(evet) => console.debug('onFocus', evet)}
              // onBlur={(evet) => console.debug('onBlur', evet)}
              // onMouseDown={onInputSelect}
              ref={inputFieldRef}
            />
          </Combobox.Input>
          <Combobox.Button
            className="absolute inset-y-0 dark:bg-gray-800 dark:rounded-r-md right-0 flex items-center pr-2"
            onClick={onInputSelect}
          >
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options
            className="poltio-dropdown-list__options"
            style={{ zIndex: 100 }}
          >
            <SearchFieldItems
              data={items}
              containerRef={containerRef}
              onSelectCallback={_OnItemChange}
              // onCloseCallback={() => setIsActive(false)}
              loading={loading}
              type={type}
              initialFocus={inputFieldRef}
              dialogProps={{
                className: 'max-h-64 overflow-y-auto',
              }}
              {...rest}
            />
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

SearchField = React.forwardRef(SearchField);

export default SearchField;
