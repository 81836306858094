import { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NotificationsCTX } from '../../contexts/Notification';
import { getContent } from '../../api';
import Loading from '../Common/Loading';

import type { Answer, Card, Content, Question } from '../../core/types';
import Canvas from './Canvas';
import Heading from '../Common/Page/Heading';
import { Helmet } from 'react-helmet';

const CanvasView = () => {
  const [data, setData] = useState<Content>();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { error } = useContext(NotificationsCTX);

  const [cards, setCards] = useState<Card[]>([]);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getContent(id);
        const ld = localStorage.getItem(`canvas_${id}`) ?? '{}';
        const localData = JSON.parse(ld);

        setData(data?.content);
        setCards(
          data?.content?.questions.map((q: Question, i: number) => {
            return {
              id: q.id,
              coordinates: localData?.cards?.filter(
                (c: Card) => c.id === q.id
              )[0]
                ? localData?.cards?.filter((c: Card) => c.id === q.id)[0]
                    .coordinates
                : { x: i * 500 + 5, y: ((i % 2) + 1) * 40 },
              title: q.title,
              background: q.background,
              answers: q.answers.map((a: Answer) => {
                return { id: a.id, title: a.title, background: a.background };
              }),
              conditions: q.conditions?.split(','),
            };
          })
        );
        setLoading(false);
      } catch (e) {
        console.log(e);
        error('Error occured!');
      }
    } else {
      navigate('/content');
    }
  }, [error, navigate, id]);

  useEffect(() => {
    document.getElementsByTagName('main')[1]?.scrollTo(0, 0);
    getData();
  }, [getData]);

  useEffect(() => {
    if (cards.length) {
      localStorage.setItem(`canvas_${id}`, JSON.stringify({ cards }));
    }
  }, [cards]);

  return loading ? (
    <Loading />
  ) : (
    <div className="mx-2">
      <div className="p- mx-auto max-w-full sm:px-6 md:px-8">
        <Helmet>
          <title>{`Canvas: ${data?.name ?? data?.title} | Poltio Platform`}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Heading
          title={`Content Canvas View: ${data?.name ?? data?.title}`}
          body={
            "You can see your content flow and you can edit your content's questions condition."
          }
        />
      </div>
      {id ? (
        <Canvas cards={cards} setCards={setCards} publicId={id} data={data} />
      ) : null}
    </div>
  );
};

export default CanvasView;
