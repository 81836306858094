import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const fonts = {
  normal: 'font-normal',
  semibold: 'font-semibold',
  medium: 'text-sm font-medium',
  sm: 'text-sm',
};

const Label = ({ htmlFor, text, font }) => (
  <label
    htmlFor={htmlFor}
    className={classNames(
      'block text-gray-900 dark:text-gray-200',
      font ? fonts[font] : fonts['normal']
    )}
  >
    {text}
  </label>
);

export { Label };
export default Label;
