import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Loading from '../Loading';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type ContentProps = {
  children: React.ReactNode;
};
const Content = ({ children }: ContentProps) => {
  const [hasOverlay, setHasOverlay] = useState(false);

  const showOverlay = () => setHasOverlay(true);
  const closeOverlay = () => setHasOverlay(false);

  return (
    <>
      <Transition
        as={Fragment}
        show={hasOverlay}
        enter="transition-opacity duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={classNames(
            hasOverlay ? 'loading-overlay' : 'hidden -z-50',
            '-px-4 sm:-ml-6'
          )}
        >
          <div className="flex justify-center h-full">
            <ArrowUpIcon className="place-self-center h-10 w-10 animate-bounce" />
          </div>
        </div>
      </Transition>
      <div className="mt-6 relative flex-1">
        {!!children &&
          React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child as React.ReactElement<any>, {
                  showOverlay,
                  closeOverlay,
                })
              : child
          )}
      </div>
    </>
  );
};

const _Header = (title: string, setOpen: (val: boolean) => void) => (
  <div className="header">
    <Dialog.Title
      as="h3"
      className="text-lg font-medium text-gray-900 dark:text-gray-200"
    >
      {title}
    </Dialog.Title>
    <div className="ml-3 h-7 flex items-center">
      <button className="close-btn" onClick={() => setOpen(false)}>
        <span className="sr-only">Close panel</span>
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </div>
);

type SlideOverProps = {
  title: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  isLoading?: boolean;
  children: React.ReactNode;
};
const SlideOver = ({
  title,
  open,
  setOpen,
  isLoading = false,
  children,
}: SlideOverProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="poltio-image-input-slide-over"
        open={open}
        onClose={setOpen}
        static
      >
        {isLoading ? (
          <div className="fixed inset-y-0 max-w-md right-0 flex w-screen justify-center bg-black bg-opacity-70 transition-opacity z-20">
            <div className="flex justify-center h-full">
              <Loading />
            </div>
          </div>
        ) : null}
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="dialog-overlay" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="container" id="imageinputslideover">
                  {_Header(title, setOpen)}
                  {!!children && <Content>{children}</Content>}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
