import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { PlusIcon, MapIcon } from '@heroicons/react/24/outline';

import { Expander } from '../../../../../Common/Form';
import { EditorCTX } from '../../../../context';
import { ConfTypes } from '../../../../enums';

import { ResultsList } from '../List';
import ResultModal from './ResultModal';
import messages from '../../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const _AddNewButton = ({ intl: { formatMessage }, searchable }) => {
  const { addNewResult } = useContext(EditorCTX);

  return (
    <div className="flex justify-center py-2 mt-4">
      <button
        className={classNames(
          'px-2 text-xs poltio-btn poltio-btn-secondary ',
          searchable ? 'rounded-r-none' : ''
        )}
        onClick={addNewResult}
        disabled={!addNewResult}
      >
        <PlusIcon className="h-5 mr-2" />
        {formatMessage(messages.AddNewResult)}
      </button>
    </div>
  );
};

const _MapResultButton = ({
  data,
  open,
  setOpen,
  contentId,
  intl: { formatMessage },
}) => {
  return (
    <div className="flex justify-center py-2 mt-4">
      <button
        className="px-2 text-xs poltio-btn poltio-btn-secondary rounded-l-none border-l-0"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MapIcon className="h-5 mr-2" />
        {formatMessage(messages.ResultMapping)}
      </button>
      <ResultModal
        open={open}
        setOpen={setOpen}
        data={data}
        contentId={contentId}
      />
    </div>
  );
};

const AddNewButton = injectIntl(_AddNewButton);

const MapResultButton = injectIntl(_MapResultButton);

const ResultList = ({ intl: { formatMessage }, modalOpen, setModalOpen }) => {
  const { focus, confType: activeType, data } = useContext(EditorCTX);
  return (
    <Expander
      as="div"
      open={activeType === ConfTypes.Result}
      className="overflow-hidden bg-white dark:bg-gray-700 rounded-lg shadow divide-y divide-gray-200 dark:divide-gray-200"
    >
      <Expander.Button
        onClick={() => focus(ConfTypes.Result, '')}
        className="flex w-full px-2 py-2 sm:px-2"
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-2 mt-1 mb-2 dark:text-gray-200">
            <h2 className="flex text-xl">{formatMessage(messages.Results)}</h2>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">
          <ResultsList />
          <div className="flex h-20 justify-center">
            <AddNewButton searchable={data?.is_searchable} />
            {data?.is_searchable ? (
              <MapResultButton
                data={data.questions || []}
                contentId={data.id}
                open={modalOpen}
                setOpen={setModalOpen}
              />
            ) : null}
          </div>
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(ResultList);
