import { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import {
  ConditionalSection,
  Dropdown,
  Label,
  SwitchInput,
  TextInput,
  ImageInput,
  Expander,
} from '../../../../Common/Form';
import { EditorCTX } from '../../../context';
import { widgetImagePath } from '../../../../../core/constants';
import CalculatorFormula from '../../Settings/CalculatorFormula/CalculatorFormula';
import messages from '../../Settings/messages';
import { ConfTypes } from '../../../enums';
import { SwitchContentType } from '../../SwitchType';
import Button from '../../../../Common/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const playOnceStrategy = [
  { title: 'Start', value: 'start' },
  { title: 'Result', value: 'result' },
];

const OptionsSection = ({ intl: { formatMessage } }) => {
  const { data, set, confType, focus } = useContext(EditorCTX);
  const [rpp, setRpp] = useState(1);

  const inputs = (
    <>
      <div className="mb-4">
        <Button.Secondary
          onClick={() => focus(ConfTypes.Question, '')}
          className="w-full"
        >
          {formatMessage(messages.ContinueWith)}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="green"
            className="w-6 h-6 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 12.75 6 6 9-13.5"
            />
          </svg>
        </Button.Secondary>
      </div>
      <div className="mb-2">
        <SwitchContentType formatMessage={formatMessage} messages={messages} />
      </div>
      <SwitchInput
        value={data?.display_repeat || false}
        onSwitch={(value) => {
          set('options.display_repeat', value ? 1 : 0);
        }}
        description={formatMessage(messages.ShowRepeatButtonDesc)}
      >
        <Label text={formatMessage(messages.ShowRepeatButton)} />
      </SwitchInput>
      <SwitchInput
        value={data?.hide_counter ? false : true}
        onSwitch={(value) => {
          set('options.hide_counter', value ? 0 : 1);
        }}
        description={formatMessage(messages.HideVoteCounterDesc)}
      >
        <Label text={formatMessage(messages.ShowVC)} />
      </SwitchInput>
      <SwitchInput
        value={data?.hide_results ? false : true}
        onSwitch={(value) => {
          set('options.hide_results', value ? 0 : 1);
        }}
        description={formatMessage(messages.HidePercDesc)}
      >
        <Label text={formatMessage(messages.ShowPerc)} />
      </SwitchInput>
      <SwitchInput
        value={data?.skip_start || false}
        disabled={data.type === 'quiz'}
        onSwitch={(value) => {
          set('options.skip_start', value ? 1 : 0);
        }}
        description={formatMessage(messages.SkipStartDesc)}
      >
        <Label text={formatMessage(messages.SkipStart)} />
      </SwitchInput>
      {data.type === 'quiz' ? (
        <div className="py-1 mr-4 flex items-center text-xs text-red-400">
          {formatMessage(messages.CannotUse)}
        </div>
      ) : null}
      <SwitchInput
        value={data?.skip_result || false}
        onSwitch={(value) => {
          set('options.skip_result', value ? 1 : 0);
        }}
        description={formatMessage(messages.SkipResultDesc)}
      >
        <Label text={formatMessage(messages.SkipResult)} />
      </SwitchInput>
      <SwitchInput
        value={data?.is_calculator || false}
        onSwitch={(value) => {
          // use bool type because of ArcheType Preset usage
          set('options.is_calculator', value);
          if (value) {
            set('options.result_loading', 1);
          }
        }}
        description={formatMessage(messages.CalculatorSetDesc)}
      >
        <Label text={formatMessage(messages.CalculatorSet)} />
      </SwitchInput>
      {data?.is_calculator ? <CalculatorFormula /> : null}
      <SwitchInput
        value={data?.is_searchable || false}
        onSwitch={(value) => {
          // use bool type because of ArcheType Preset usage
          set('options.is_searchable', value);
          if (value) {
            set('options.result_loading', 1);
          }
        }}
        description={formatMessage(messages.SearchableSetDesc)}
      >
        <Label text={'Searchable Set'} />
      </SwitchInput>

      <SwitchInput
        value={data?.attributes?.gives_feedback || false}
        onSwitch={(value) => {
          set('options.attributes.gives_feedback', value ? 1 : 0);
        }}
        description={formatMessage(messages.ShowCorrectDesc)}
      >
        <Label text={formatMessage(messages.ShowCorrect)} />
      </SwitchInput>
      <SwitchInput
        value={data?.attributes?.display_results || false}
        onSwitch={(value) => {
          set('options.attributes.display_results', value ? 1 : 0);
        }}
        description={formatMessage(messages.ShowScoreDesc)}
      >
        <Label text={formatMessage(messages.ShowScore)} />
      </SwitchInput>
      <SwitchInput
        value={data?.result_loading || false}
        onSwitch={(value) => {
          set('options.result_loading', value ? 1 : 0);
        }}
        description={formatMessage(messages.ResultIndicatorDesc)}
      >
        <Label text={formatMessage(messages.ResultIndicator)} />
      </SwitchInput>

      <ConditionalSection
        label={formatMessage(messages.PlayOnce)}
        checked={data?.play_once || false}
        onSwitch={(value) => {
          set('options.play_once', value ? 1 : 0);
          set('options.play_once_strategy', 'result');
          if (!value) {
            set('options.play_once_msg', '');
            set('options.play_once_btn', '');
            set('options.play_once_img', '');
            set('options.play_once_link', '');
            set('options.play_once_strategy', '');
          }
        }}
        description={formatMessage(messages.PlayOnceDesc)}
      >
        <div className="pb-2">
          <Label text={formatMessage(messages.PlayOnceStrategy)} />
          <Dropdown
            data={playOnceStrategy}
            selectedIdx={playOnceStrategy.findIndex(
              (opt) => opt.value === data?.play_once_strategy
            )}
            onChange={(value) =>
              set('options.play_once_strategy', playOnceStrategy[value].value)
            }
            description={formatMessage(messages.PlayOnceStrategyDesc)}
          />
        </div>
        <div className="pb-2">
          <TextInput
            name="play_once_msg"
            label={formatMessage(messages.PlayOnceMessage)}
            value={data?.play_once_msg || ''}
            type="text"
            debounce={{ timeout: 1000 }}
            onChange={(value) => set('options.play_once_msg', value)}
            description={formatMessage(messages.PlayOnceMessageDesc)}
          />
        </div>
        <div className="pb-2">
          <TextInput
            name="play_once_link"
            label={formatMessage(messages.PlayOnceLink)}
            value={data?.play_once_link}
            type="text"
            debounce={{ timeout: 1000 }}
            onChange={(value) => set('options.play_once_link', value)}
            description={formatMessage(messages.PlayOnceLinkDesc)}
          />
        </div>
        <div className="pb-2">
          <TextInput
            name="play_once_btn"
            label={formatMessage(messages.PlayOnceButton)}
            value={data?.play_once_btn}
            type="text"
            debounce={{ timeout: 1000 }}
            onChange={(value) => set('options.play_once_btn', value)}
            description={formatMessage(messages.PlayOnceButtonDesc)}
          />
        </div>
        <div className="pb-2">
          <Label text={formatMessage(messages.PlayOnceImage)} />
          <ImageInput
            title={formatMessage(messages.PlayOnceImage)}
            src={
              data?.play_once_img
                ? `${widgetImagePath}/${data?.play_once_img}`
                : ''
            }
            callback={(value) => set('options.play_once_img', value)}
          />
          <div className="py-1  text-xs text-gray-400">
            <Label text={formatMessage(messages.PlayOnceImageDesc)} />
          </div>
        </div>
      </ConditionalSection>
      <ConditionalSection
        label={formatMessage(messages.EndDate)}
        checked={data?.end_date || false}
        onSwitch={(value) => {
          set('options.end_date', value ? 1 : 0);
          set('options.end_date_day', 30);
          set('options.end_date_hour', 0);
          set('options.end_date_minute', 0);
          if (!value) {
            set('options.end_date_day', 0);
            set('options.end_date_hour', 0);
            set('options.end_date_minute', 0);
          }
        }}
        description={formatMessage(messages.EndDateDesc)}
      >
        <div className="pb-2">
          <TextInput
            name="days"
            label={formatMessage(messages.Days)}
            type="number"
            value={data?.end_date_day || 30}
            min={0}
            debounce={{ timeout: 1000 }}
            onChange={(value) => set('options.end_date_day', value)}
          />
        </div>
        <div className="pb-2">
          <TextInput
            name="hours"
            label={formatMessage(messages.Hours)}
            type="number"
            value={data?.end_date_hour || 0}
            min={0}
            max={60}
            debounce={{ timeout: 1000 }}
            onChange={(value) => set('options.end_date_hour', value)}
          />
        </div>
        <div className="pb-2">
          <TextInput
            name="minutes"
            label={formatMessage(messages.Minutes)}
            type="number"
            value={data?.end_date_minute || 0}
            min={0}
            max={60}
            debounce={{ timeout: 1000 }}
            onChange={(value) => set('options.end_date_minute', value)}
          />
        </div>
      </ConditionalSection>

      <SwitchInput
        value={data?.attributes?.show_timer || false}
        onSwitch={(value) => {
          set('options.attributes.show_timer', value ? 1 : 0);
        }}
        description={formatMessage(messages.ShowTimerDesc)}
      >
        <Label text={formatMessage(messages.ShowTimer)} />
      </SwitchInput>

      <TextInput
        name="time_limit"
        label={formatMessage(messages.TimeLimit)}
        type="number"
        value={data?.attributes?.time_limit || 0}
        min={0}
        debounce={{ timeout: 1000 }}
        onChange={(value) => set('options.attributes.time_limit', value)}
        description={formatMessage(messages.TimeLimitDesc)}
      />

      <TextInput
        name="pool_question_count"
        label={formatMessage(messages.PoolQuestionCount)}
        type="number"
        value={data?.attributes?.pool_question_count || 0}
        min={0}
        debounce={{ timeout: 1000 }}
        onChange={(value) =>
          set('options.attributes.pool_question_count', value)
        }
        description={formatMessage(messages.PoolQuestionCountDesc)}
      />

      <TextInput
        name="search_results_per_page"
        label={formatMessage(messages.SearchPerPage)}
        type="number"
        value={data?.search_results_per_page ?? 5}
        min={1}
        debounce={{ timeout: 1000 }}
        onChange={(value) => {
          setRpp(value);
          set(
            'options.search_results_per_page',
            Math.max(1, Math.min(10, Number(value)))
          );
        }}
        description={
          rpp === 0
            ? formatMessage(messages.ResultPerPageDesc)
            : formatMessage(messages.ResultPerPageDescNormal)
        }
      />
      <p className="text-xs text-gray-400">
        {formatMessage(messages.ResultPerPageMaxDesc)}{' '}
        <span className="text-red-500">10</span>.
      </p>

      <div className="py-3">
        <TextInput
          name={'recom_title'}
          label={formatMessage(messages.CustomRecomTitle)}
          type="text"
          value={data?.attributes?.recom_title || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => set('options.attributes.recom_title', value)}
          description={formatMessage(messages.CustomRecomTitleDesc)}
        />
        <SwitchInput
          value={data?.attributes?.noindex || false}
          onSwitch={(value) => {
            set('options.attributes.noindex', value ? 1 : 0);
          }}
        >
          <Label text={formatMessage(messages.NoIndex)} />
        </SwitchInput>
        <p className="text-xs text-gray-400">
          {formatMessage(messages.NoIndexDesc)}
          <a
            className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
            href="https://developers.google.com/search/docs/crawling-indexing/block-indexing"
            target="_blank"
            rel="noreferrer"
          >
            {' Ref'}
          </a>
        </p>
      </div>
    </>
  );

  return (
    <Expander
      open={confType === ConfTypes.Options}
      as="div"
      className="overflow-hidden bg-white dark:bg-gray-700 rounded-lg shadow divide-y divide-gray-200 dark:divide-gray-500"
    >
      <Expander.Button
        className="flex w-full px-2 py-2 sm:px-2"
        onClick={() => focus(ConfTypes.Options, '')}
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-2 mt-1 mb-2 dark:text-gray-200">
            <h2 className="flex text-xl">{formatMessage(messages.Options)}</h2>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">{inputs}</div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(OptionsSection);
