import React from 'react';

import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';

function animateLayoutChanges(args) {
  const { isSorting, wasSorting } = args;

  if (isSorting || wasSorting) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}

const _SortableItem = (props) => {
  const {
    getNewIndex,

    id,
    index,
    data,

    component,
    componentProps,
    isQuestion,

    ...rest
  } = props;

  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    getNewIndex,
  });

  return React.createElement(component, {
    ref: setNodeRef,

    dragging: isDragging,
    sorting: isSorting,
    handleProps: {
      ref: setActivatorNodeRef,
    },
    isQuestion: isQuestion,
    transform: transform,
    transition: transition,
    listeners: listeners,
    'data-index': index,
    'data-id': id,

    ...componentProps,
    ...rest,
    ...attributes,
  });
};

export const SortableItem = _SortableItem;
