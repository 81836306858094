import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import FormHelpText from './FormHelpText';

import { Label } from './Label';

const AutoGrowInput = ({
  name,
  rows = 1,
  maxRows = 10,
  placeholder = '',
  value = '',
  onChange = () => {},
  ...rest
}) => {
  const textRef = useRef();

  const [text, setText] = useState(value);
  useEffect(() => setText(value), [value]);

  const textareaLineHeight = 20;

  useEffect(() => {
    const currentRows = ~~(textRef.current.scrollHeight / textareaLineHeight);
    textRef.current.rows = Math.min(maxRows, Math.max(currentRows, rows));
  }, [maxRows, rows]);

  const handleChange = (event) => {
    const { rows, value: eventValue } = event.target;
    const currentRows = ~~(textRef.current.scrollHeight / textareaLineHeight);
    textRef.current.rows = Math.min(maxRows, Math.max(currentRows, rows));
    setText(eventValue);
  };

  const calls = (event) => {
    handleChange(event);
    onChange(event);
  };

  return (
    <textarea
      ref={textRef}
      name={name}
      rows={rows}
      className="block w-full shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md resize-none"
      value={text}
      onChange={calls}
      placeholder={placeholder}
      {...rest}
    />
  );
};

const Input = (props = {}) => {
  const { name, label, htmlFor, font, helpText, autogrow, maxLength, ...rest } =
    props;
  return (
    <>
      <div className="flex row-auto">
        {label && <Label htmlFor={htmlFor || name} text={label} font={font} />}
        {maxLength && (
          <span className="ml-1 text-sm text-gray-400 dark:text-gray-300 mt-0.5">
            - {maxLength - props.value.length}
          </span>
        )}
      </div>

      <div className="relative mt-1">
        {autogrow ? (
          <AutoGrowInput name={name} {...rest} />
        ) : (
          <textarea
            className="block w-full shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
            {...rest}
          />
        )}
        {helpText && <FormHelpText helpText={helpText} />}
      </div>
    </>
  );
};

const DebouncedInput = (props) => {
  const { debounce, ...rest } = props;

  const [value, setValue] = useState(props?.value);
  useEffect(() => setValue(props?.value), [props?.value]);

  const debounced = useDebouncedCallback(
    (value) => props?.onChange(value),
    debounce?.timeout || 500,
    { ...debounce?.options }
  );

  const onChange = (event) => {
    setValue(event.target.value);
    debounced(event.target.value);
  };

  return Input({ ...rest, value: value, onChange: onChange });
};

const TextAreaInput = ({ debounce, ...rest }) => {
  const _props = Object.assign(
    {
      rows: 1,
      label: '',
      htmlFor: '',
      font: 'normal',
      placeholder: '',
      helpText: '',
      value: '',
      onChange: () => {},
      autogrow: false,
    },
    { ...rest }
  );

  return debounce
    ? DebouncedInput({ debounce: debounce, ..._props })
    : Input(_props);
};

export { TextAreaInput };
export default TextAreaInput;
