import { useState, useContext } from 'react';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';

import CoverForm from './CoverForm';
import QuestionForm from './QuestionForm';
import ResultForm from './ResultForm';
import { EditorCTX } from '../../context';
import OptionsSection from './OptionsSection';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditorContentDetail = () => {
  const [show, setShow] = useState(true);
  const { data } = useContext(EditorCTX);

  return (
    <div
      className={classNames(
        'sm:h-full flex flex-shrink-0',
        show ? 'w-full' : '',
        'max-w-[100%] md:max-w-[25rem]'
      )}
    >
      <div className={classNames('flex flex-col', show ? 'w-full' : 'w-0')}>
        <h2 className="text-2xl font-semibold mb-3 mt-2 mx-6 text-center break-words line-clamp-3 dark:text-gray-200">
          {data.name ? data?.name : data?.title}
        </h2>
        <div className="sm:relative flex flex-col flex-1 overflow-y-auto fancy-scrollbar">
          <div
            className={classNames(
              'sm:absolute inset-0',
              show ? 'w-full' : 'sm:hidden'
            )}
          >
            <div className="mx-2 sm:ml-6 sm:mr-1 space-y-4">
              <CoverForm />
              <OptionsSection />
              <QuestionForm />
              <ResultForm />
            </div>
            <div className="h-8 sm:h-16" />
          </div>
        </div>
      </div>
      <button
        className="hover:bg-gray-300 w-4 group"
        onClick={() => setShow((prev) => !prev)}
      >
        <div className="fixed h-1/2">
          <ChevronDoubleLeftIcon
            className={classNames(
              'h-4 w-4 dark:text-gray-200 group-hover:text-black',
              show ? '' : 'rotate-180'
            )}
          />
        </div>
      </button>
    </div>
  );
};

export default EditorContentDetail;
