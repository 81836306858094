import api from './client';
import {
  clientId as client_id,
  clientSecret as client_secret,
} from '../core/constants';
import { languageWithoutRegionCode, getQuery } from '../core/helper';
import history from '../core/history';

import * as dataSource from './dataSource';
import * as sage from './sage';
export { dataSource, sage };

export function login(email, password, returnUrl = '/') {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': languageWithoutRegionCode,
    Authorization: '',
  };

  return api
    .post('/platform/auth/login', { client_id, client_secret, email, password })
    .then((response) => {
      if (response?.data?.user?.level > 11) {
        api.defaults.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': languageWithoutRegionCode,
          Authorization: `Bearer ${response.data.token}`,
        };

        window.localStorage.setItem(
          'token',
          JSON.stringify(response.data.token)
        );

        window.gtag('event', 'login', {});

        window.location.href = decodeURIComponent(returnUrl ?? '/');
      } else {
        //toast.warn('Please contact support@poltio.com');
      }
    });
}

export function forgotPassword(email) {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': languageWithoutRegionCode,
    Authorization: '',
  };

  return api.post('/platform/auth/password/forget', {
    client_id,
    client_secret,
    email,
  });
}

export function register(
  email,
  password,
  first_name,
  last_name,
  accepted,
  password_confirmation,
  returnUrl = '/'
) {
  return api
    .post('/platform/auth/register', {
      email,
      password,
      client_id,
      client_secret,
      first_name,
      last_name,
      accepted,
      password_confirmation,
    })
    .then((response) => {
      if (response?.data?.user?.level > 11) {
        api.defaults.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': languageWithoutRegionCode,
          Authorization: `Bearer ${response.data.token}`,
        };

        window.localStorage.setItem(
          'token',
          JSON.stringify(response.data.token)
        );

        window.gtag('event', 'sign_up', {});

        window.location.href = decodeURIComponent(returnUrl);
      } else {
        //toast.warn('Please contact support@poltio.com');
      }
    });
}

export function verify(email, token) {
  return api.get(`/platform/auth/verify/${email}/${token}`, {
    client_id,
    client_secret,
    email,
    token,
  });
}

export function reSendVerify() {
  return api.post('/platform/settings/resend-verification');
}

export function resetPassword(email, password, token, password_confirmation) {
  return api.post('/platform/auth/password/reset', {
    email,
    password,
    client_id,
    client_secret,
    token,
    password_confirmation,
  });
}

export function logout() {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  history.push('/login');
}

export function sendDemoRequest(formData) {
  return api.post('/platform/dashboard/contact-us', formData);
}

export function loginLinkRequest(email) {
  return api.post('/platform/auth/login-with-email', {
    client_id,
    client_secret,
    email,
  });
}

export function loginWithEmail(email, token, returnUrl) {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': languageWithoutRegionCode,
    Authorization: '',
  };

  return api
    .post('/platform/auth/login-with-email-token', {
      client_id,
      client_secret,
      email,
      token,
    })
    .then((response) => {
      if (response.data.token) {
        api.defaults.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': languageWithoutRegionCode,
          Authorization: `Bearer ${response.data.token}`,
        };

        window.localStorage.setItem(
          'token',
          JSON.stringify(response.data.token)
        );

        window.gtag('event', 'login-with-email', {});

        window.location.href = returnUrl;
      } else {
        //handled in comp
      }
    });
}

export function submitPass(passData) {
  return api.put('/platform/settings/password', {
    password: passData.old,
    new_password: passData.new,
    new_password_confirmation: passData.repeat,
  });
}

export function updatePassword(_old, _new, _repeat) {
  return submitPass({ old: _old, new: _new, repeat: _repeat });
}

export function logoutSessions(password) {
  return api.post('/platform/account/sessions', { password });
}

export function getProfile() {
  return api.get(`/platform/account/profile`);
}

export function getDashboard() {
  return api.get(`/platform/dashboard`);
}

export function getDashboardStats(start, end) {
  return api.get(
    `/platform/dashboard/stats?${start ? `start=${start}&end=${end}` : ''}`
  );
}

export function uploadImage(f, ext) {
  return api.post('/platform/content/upload', { f, ext });
}

export function submitSettings(settingsData) {
  return api.put('/platform/settings', { ...settingsData });
}

export function getWebhooks(id, last_id, q) {
  let query = getQuery(last_id, q, id);
  return api.get(`/platform/hooks/web${query}`);
}

export function webhooksSave(params) {
  return params.hook_id
    ? api.put(`/platform/hooks/web/${params.hook_id}`, params)
    : api.post('/platform/hooks/web', params);
}

export function webhooksDelete(id) {
  return api.delete(`/platform/hooks/web/${id}`);
}

export function getWebhookLogs(lead_id, page, size) {
  let query = getQuery(page, size);
  return api.get(`/platform/hooks/web/${lead_id}/logs${query}`);
}

export function getSheethooks(id, last_id, q) {
  let query = getQuery(last_id, q, id);
  return api.get(`/platform/hooks/sheet${query}`);
}

export function sheethooksSave(params) {
  return params.hook_id
    ? api.put(`/platform/hooks/sheet/${params.hook_id}`, params)
    : api.post('/platform/hooks/sheet', params);
}

export function sheethooksDelete(id) {
  return api.delete(`/platform/hooks/sheet/${id}`);
}

export function getSheethookLogs(lead_id, page, size) {
  let query = getQuery(page, size);
  return api.get(`/platform/hooks/sheet/${lead_id}/logs${query}`);
}

export function getPixelCodesPaginated(last_id, q) {
  let query = getQuery(last_id, q);
  return api.get(`/platform/pixel-codes${query}`);
}

export function pcSave(code, name, id) {
  return id
    ? api.put(`/platform/pixel-codes/${id}`, { code, name })
    : api.post('/platform/pixel-codes', { code, name });
}

export function pcDelete(id) {
  return api.delete(`/platform/pixel-codes/${id}`);
}

export function getContents(
  page = 1,
  per_page = 12,
  order = 'created_at',
  sort = 'desc',
  type = [],
  searchText = ''
) {
  let query = getQuery(page, per_page);
  return api.get(
    `/platform/content${query}&order=${order}&sort=${sort}${
      type
        ? type.reduce((acc, val) => {
            const comma = '&type[]=';
            return acc + comma + val;
          }, '')
        : ''
    }${searchText && `&q=${searchText}`}`
  );
}

export function getContentStats(id, start, end) {
  return api.get(
    `/platform/content/${id}/stats?${start ? `start=${start}&end=${end}` : ''}`
  );
}

export function getDrafts(
  page = 1,
  per_page = 12,
  order = 'created_at',
  sort = 'desc',
  type = [],
  searchText = ''
) {
  let query = getQuery(page, per_page);
  return api.get(
    `/platform/content/drafts${query}&order=${order}&sort=${sort}${
      type
        ? type.reduce((acc, val) => {
            const comma = '&type[]=';
            return acc + comma + val;
          }, '')
        : ''
    }${searchText && `&q=${searchText}`}`
  );
}

export function getThemes(page = 1, per_page = 12) {
  let query = getQuery(page, per_page);
  return api.get(`/platform/theme${query}`);
}

export function saveTheme(data, theme_id) {
  const name = data.name;
  return theme_id
    ? api.put(`/platform/theme/${theme_id}`, { name, theme: data })
    : api.post('/platform/theme/', { name, theme: data });
}

export function deleteTheme(theme_id) {
  return api.delete(`/platform/theme/${theme_id}`);
}

export function getDefaultTheme() {
  return api.get('/platform/theme/default');
}

export function getResults(
  public_id,
  page,
  size,
  order_dir = 'desc',
  order_by = 'click_count'
) {
  let query = getQuery(page, size);
  return api.get(
    `/platform/content/${public_id}/results${query}&order_by=${order_by}&order_dir=${order_dir}`
  );
}

export function finishTest(id) {
  return api.post('/create/test-finish', { id });
}

export function getMetricsMonthly(query, dir = 'asc', count = 10) {
  return api.get(
    `/platform/dashboard/metrics/month?metrics=${query}&dir=${dir}&count=${count}`
  );
}

export function getMetricsDaily(query, dir = 'asc', count = 7) {
  return api.get(
    `/platform/dashboard/metrics/day?metrics=${query}&dir=${dir}&count=${count}`
  );
}

export function getDashboardMetrics(timespan, body) {
  return api.post(`/platform/dashboard/metrics/${timespan}`, body);
}

export function getContentMetrics(public_id, timespan, body) {
  return api.post(`/platform/content/${public_id}/metrics/${timespan}`, body);
}

export function duplicateContent(public_id) {
  return api.get(`/platform/content/${public_id}/duplicate`);
}

export function getContent(public_id) {
  return api.get(`/platform/content/${public_id}`);
}

export function getFreeTypeAnswers(public_id, question_id, page, size) {
  let query = getQuery(page, size);
  return api.get(
    `/platform/content/${public_id}/question/${question_id}/inputs${query}`
  );
}

export function getVoters(public_id, page, size) {
  let query = getQuery(page, size);
  return api.get(`/platform/content/${public_id}/voters${query}`);
}

export function getSessions(public_id, page, size) {
  let query = getQuery(page, size);
  return api.get(`/platform/content/${public_id}/sessions${query}`);
}

export function getVoteSources(public_id) {
  return api.get(`/platform/content/${public_id}/session/urls`);
}

export function getVotersRequest(public_id, page, size) {
  return api.get(
    `/platform/content/${public_id}/voters?download&page=${page}&per_page=${size}`
  );
}

export function getEmbedSettings(type) {
  return api.get(`/publisher-embed-settings/${type}`);
}

export function setEmbedSettings(type, data) {
  return api.post(`/publisher-embed-settings/${type}`, { settings: data });
}

export function getReportRequests(page, size) {
  let query = getQuery(page, size);
  return api.get(`/platform/reports${query}`);
}

export function setReportRequest(data) {
  return api.post('/platform/reports', data);
}

export function getTriviaHistory(page, size) {
  return api.get(`/trivia/history?page=${page}&per_page=${size}`);
}

export function getPlayerList(id, page, size, winners_only) {
  return api.get(
    `/trivia/playerlist/${id}?page=${page}&per_page=${size}&winners_only=${winners_only}`
  );
}

export function getTriviaActions(id) {
  return api.get(`/trivia/actions/${id}`);
}

export function getTriviaCredit() {
  return api.get(`/trivia-credits`);
}

export function triviaInit(id, options) {
  return api.post(`/trivia/init`, { quiz_id: id, ...options });
}

export function triviaAction(id, action) {
  return api.get(`/trivia/${action}/${id}`);
}

export function getTriviaInfo(id) {
  return api.get(`/trivia/${id}`);
}

export function getTriviaState(id) {
  return api.get(`/trivia/state/${id}`);
}

export function getTopPlayers(id) {
  return api.get(`/trivia/top-players/${id}`);
}

export function setRedirectUrl(id, url) {
  return api.post('/trivia/set-redirect-url', { quiz_id: id, url });
}

export function deleteContent(id) {
  return api.delete(`/platform/content/${id}`);
}

export function getSankeyMetrics(public_id) {
  return api.get(`/platform/content/${public_id}/sankey`);
}

export function getSankey(public_id, from_id, to_id, page, size) {
  let query = getQuery(page, size);
  return api.get(
    `/platform/content/${public_id}/sankey/users/${from_id}/${to_id}${query}`
  );
}

export function getDataSources(last_id, q) {
  let query = getQuery(last_id, q);
  return api.get(`/platform/data-sources${query}`);
}

export function setDataSource(dataSource, config = {}) {
  return api.post('/platform/data-sources', dataSource, config);
}

export function deleteDataSource(id) {
  return api.delete(`/platform/data-sources/${id}`);
}

export function addDataSourceNote(id, note) {
  return api.post(`/platform/data-sources/${id}/note`, note);
}

export function apiSageDSRequest(dataSourceId) {
  return api.post(
    `/platform/sage/jobs/`,
    { recipe: 'product_finder', data_source_id: `${dataSourceId}` },
    {
      timeout: 90000,
    }
  );
}

export function getTokens(last_id, q) {
  let query = getQuery(last_id, q);
  return api.get(`/platform/tokens${query}`);
}

export function addToken(name, period) {
  return api.post(`/platform/tokens`, {
    name: `${name}`,
    expires: `${period}`,
  });
}

export function revokeToken(id) {
  return api.delete(`/platform/tokens/${id}`);
}

export function getDomains() {
  return api.get(`/platform/domains`);
}

export function saveDomain(params) {
  return params.id
    ? api.put(`/platform/domains/${params.id}`, {
        is_active: params.is_active,
        is_default: params.is_default,
      })
    : api.post(`/platform/domains`, params);
}

export function removeDomain(id) {
  return api.delete(`/platform/domains/${id}`);
}

export function getDynWidgets(id, last_id, q) {
  let query = getQuery(last_id, q, id);
  return api.get(`/platform/widgets${query}`);
}

export function dynWidgetSave(params) {
  return params.id
    ? api.put(`/platform/widgets/${params.id}`, params)
    : api.post('/platform/widgets', params);
}

export function dynWidgetDelete(id) {
  return api.delete(`/platform/widgets/${id}`);
}

export function getConditions(id) {
  return api.get(`/platform/content/${id}/conditions`);
}

export function addCondition(public_id, questionId, answerId) {
  return api.post(
    `/platform/content/${public_id}/question/${questionId}/conditions/add`,
    {
      answer_id: answerId,
      condition_reverse: false,
    }
  );
}

export function removeCondition(public_id, questionId, answerId) {
  return api.post(
    `/platform/content/${public_id}/question/${questionId}/conditions/remove`,
    {
      answer_id: answerId,
    }
  );
}
