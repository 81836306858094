import { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  ConditionalSection,
  SwitchInput,
  TextInput,
  LeadInput,
  PixelCodeInput,
  Dropdown,
  Label,
} from '../../../../../Common/Form';

import { EditorCTX } from '../../../../context';
import { useAnswerTypes } from '../../../../enums';
import QuestionConditions from './QuestionConditions';
import messages from '../messages';

const QuestionConf = ({ intl: { formatMessage } }) => {
  const {
    data,
    activeQuestionIdx: qidx,
    set,
    showCreateNewLead,
    showCreateNewPixelCode,
  } = useContext(EditorCTX);
  const AnswerTypes = useAnswerTypes();

  const _answer_type = data?.questions?.[qidx]?.answer_type;
  const [question, setQuestions] = useState(data?.questions?.[qidx]);
  const [answers, setAnswers] = useState(question?.answers ?? []);

  useEffect(() => {
    setQuestions(data?.questions?.[qidx]);
    setAnswers(question?.answers ?? []);
  }, [data?.questions, qidx, question?.answers]);

  let maxMp = [];
  for (let i = 1; i <= answers.length; i++) {
    maxMp.push(i);
  }

  let conIds = [];
  const len = data.questions.length;

  for (let i = 0; i < len; i++) {
    if (data?.questions[i]?._conditions) {
      var a = data?.questions[i]?._conditions?.split(',');
      a.map((x) => conIds.push(x));
    }
  }

  let answerIds = [];
  for (let i = 0; i <= answers.length; i++) {
    answerIds?.push(answers[i]?._id?.toString());
  }
  const found = conIds?.some((r) => answerIds?.indexOf(r) >= 0);

  useEffect(() => {
    if (found) {
      set(`questions.${qidx}.is_skippable`, 0);
    }
  }, [found, set, qidx]);

  return (
    <>
      {question?.id ? (
        <span className="font-semibold text-xs dark:text-gray-200">{`ID: #${question?.id}`}</span>
      ) : null}

      <ConditionalSection
        label={formatMessage(messages.AllowMP)}
        checked={question?.allow_multiple_answers}
        disabled={data.type === 'quiz'}
        onSwitch={(value) => {
          set(`questions.${qidx}.allow_multiple_answers`, value);
          set(
            `questions.${qidx}.max_multi_punch_answer`,
            (question?.max_multi_punch_answer === 0
              ? question?.answers?.length
              : question?.max_multi_punch_answer) ?? 1
          );

          if (!value && _answer_type === AnswerTypes.autocomplete) {
            set(`questions.${qidx}.answer_type`, AnswerTypes.media);
          }
        }}
        description={formatMessage(messages.MPDesc)}
      >
        <div>
          <Dropdown
            label={formatMessage(messages.MaxNumber)}
            selectedIdx={question?.max_multi_punch_answer - 1}
            data={maxMp}
            min={0}
            onChange={(value) => {
              let i = value + 1;
              set(`questions.${qidx}.max_multi_punch_answer`, i);
            }}
            description={formatMessage(messages.MPCountDesc)}
          />
        </div>
      </ConditionalSection>
      {data.type === 'quiz' ? (
        <div className="py-1 mr-4 flex items-center text-xs text-red-400">
          {formatMessage(messages.CannotUse)}
        </div>
      ) : null}
      <ConditionalSection
        label={formatMessage(messages.Autocomplete)}
        checked={question?.answer_type === 'autocomplete'}
        disabled={data.type === 'quiz'}
        onSwitch={(value) => {
          set(`questions.${qidx}.autocomplete`, value);
          if (value) {
            set(`questions.${qidx}.allow_multiple_answers`, value);
          }
          set(
            `questions.${qidx}.answer_type`,
            value ? AnswerTypes.autocomplete : AnswerTypes.text
          );
        }}
        description={formatMessage(messages.AutocompleteDesc)}
      >
        <div>
          <Label
            htmlFor={'recommended_popular_answer'}
            text={formatMessage(messages.PopularAnswersCount)}
          />
          <Dropdown
            selectedIdx={question?.recommended_popular_answer ?? 0}
            data={Array.from(Array(11).keys())}
            min={0}
            onChange={(value) => {
              set(`questions.${qidx}.recommended_popular_answer`, value);
            }}
            description={formatMessage(messages.PopularAnswersDesc)}
          />
        </div>
        <div>
          <TextInput
            name="autocomplete_help"
            label={formatMessage(messages.AutocompleteHelp)}
            value={question?.autocomplete_help || ''}
            type="text"
            debounce={{ timeout: 1000 }}
            onChange={(value) => {
              set(`questions.${qidx}.autocomplete_help`, value);
            }}
            description={formatMessage(messages.AutocompleteHelpDesc)}
          />
        </div>
        <div>
          <TextInput
            name="autocomplete_placeholder"
            label={formatMessage(messages.AutocompletePlaceholder)}
            value={question?.autocomplete_placeholder || ''}
            type="text"
            debounce={{ timeout: 1000 }}
            onChange={(value) => {
              set(`questions.${qidx}.autocomplete_placeholder`, value);
            }}
            description={formatMessage(messages.AutocompletePlaceholderDesc)}
          />
        </div>
      </ConditionalSection>
      {data.type === 'quiz' ? (
        <div className="py-1 mr-4 flex items-center text-xs text-red-400">
          {formatMessage(messages.CannotUse)}
        </div>
      ) : null}
      <SwitchInput
        value={question?.is_skippable || 0}
        disabled={data.type === 'quiz'}
        onSwitch={(value) => {
          found
            ? set(`questions.${qidx}.is_skippable`, 0)
            : set(`questions.${qidx}.is_skippable`, value ? 1 : 0);
        }}
        description={formatMessage(messages.SkipQuestionDesc)}
      >
        {formatMessage(messages.SkipQuestion)}
      </SwitchInput>
      {data.type === 'quiz' ? (
        <div className="py-1 mr-4 flex items-center text-xs text-red-400">
          {formatMessage(messages.CannotUse)}
        </div>
      ) : null}
      <SwitchInput
        value={question?.rotate_answers || 0}
        onSwitch={(value) => {
          set(`questions.${qidx}.rotate_answers`, value ? 1 : 0);
          set(`questions.${qidx}.rotate_answers_last`, 0);
        }}
        description={formatMessage(messages.ShuffleRowDesc)}
      >
        {formatMessage(messages.ShuffleRow)}
      </SwitchInput>
      <SwitchInput
        value={question?.rotate_answers_last || 0}
        onSwitch={(value) => {
          set(`questions.${qidx}.rotate_answers`, 0);
          set(`questions.${qidx}.rotate_answers_last`, value ? 1 : 0);
        }}
        description={formatMessage(messages.ShuffleExceptDesc)}
      >
        {formatMessage(messages.ShuffleExcept)}
      </SwitchInput>
      <div className="pb-2">
        <TextInput
          name="luv"
          label={formatMessage(messages.LeadUrlVar)}
          value={question?.luv || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`questions.${qidx}.luv`, value);
          }}
          description={formatMessage(messages.LeadUrlVarDesc)}
        />
      </div>

      <div className="pb-2">
        <TextInput
          name="luv"
          label={formatMessage(messages.DefaultCalVal)}
          value={question?.cal_val_default || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`questions.${qidx}.cal_val_default`, value);
          }}
          description={formatMessage(messages.DefaultCalValDesc)}
        />
      </div>

      <PixelCodeInput
        pixelCode={question?.pixel_code || ''}
        onSelect={(pixelCode) => {
          set(`questions.${qidx}.pixel_code`, {
            id: pixelCode.id,
            name: pixelCode.name,
          });
        }}
        onDelete={() => {
          set(`questions.${qidx}.pixel_code`, null);
        }}
        onNew={() => {
          showCreateNewPixelCode();
        }}
      />
      <LeadInput
        leadId={question?.lead_id || question?.lead?.id || ''}
        canPropagateToAnswers={true}
        onSelect={(lead) => {
          set(`questions.${qidx}.lead.id`, lead.id);
          // set(`questions.${qidx}.answers.*.lead.id`, lead.id); // may not even need these
        }}
        onDelete={(event, shouldRemoveFromAllAnswers) => {
          set(`questions.${qidx}.lead.id`, null);
          if (shouldRemoveFromAllAnswers) {
            set(`questions.${qidx}.answers.*.lead.id`);
          }
        }}
        onNew={() => {
          showCreateNewLead();
        }}
      />
      <div className="">
        <QuestionConditions />
      </div>
    </>
  );
};

export default injectIntl(QuestionConf);
