import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import FormHelpText from './FormHelpText';
import { Label } from './Label';

const Input = ({
  name,
  text,
  label,
  autoComplete,
  step,
  type = 'text',
  placeholder = '',
  helpText = '',
  description = '',
  font = 'normal',
  value = '',
  className = 'block w-full shadow-sm sm:text-sm dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-300 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md',
  onChange = () => {},
  ...rest
}) => {
  const _value = type === 'number' ? Number(value || 0).toString() : value;

  return (
    <>
      {(text || label) && (
        <Label htmlFor={name} text={text || label} font={font} />
      )}
      <div className="relative mt-1">
        <input
          type={type}
          name={name}
          step={step}
          className={className}
          placeholder={placeholder}
          autoComplete={autoComplete}
          value={_value}
          onChange={onChange}
          {...rest}
        />
        {helpText && (
          <FormHelpText
            helpText={helpText}
            className="h-full flex justify-center align-center"
          />
        )}
        {description && (
          <div className="text-xs py-1 mb-1 text-gray-400 dark:text-gray-300">
            {description}
          </div>
        )}
      </div>
    </>
  );
};

const DebouncedInput = (props) => {
  const { debounce, ...rest } = props;

  const [value, setValue] = useState(props?.value ?? '');
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props?.value]);

  const debounced = useDebouncedCallback(
    (event) => props?.onChange(event),
    debounce?.timeout || 500,
    { ...debounce?.options }
  );

  const onChange = (event) => {
    const _in = event.target.value ?? '';
    const next = props?.type === 'number' ? Number(_in || 0) : _in;

    setValue(next);
    debounced(next);
  };

  return React.createElement(Input, {
    ...rest,
    value: value,
    onChange: onChange,
  });
};

const TextInput = ({ debounce, ...rest }) => {
  return debounce
    ? React.createElement(DebouncedInput, { debounce, ...rest })
    : React.createElement(Input, rest);
};

export { TextInput };
export default TextInput;
