import React, { useContext } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import { widgetCoverImagePath } from '../../../../../core/constants';
import messages from '../messages';

import {
  Label,
  TextAreaInput,
  ImageInput,
  Expander,
  ThemeInput,
} from '../../../../Common/Form';

import { EditorCTX } from '../../../context';
import { ConfTypes } from '../../../enums';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CoverForm = ({ intl: { formatMessage } }) => {
  const { data, focus, set, confType, isLoading } = useContext(EditorCTX);

  const { title, background, desc, name } = data;

  return (
    <Expander
      open={confType === ConfTypes.Cover}
      as="div"
      className="overflow-hidden bg-white dark:bg-gray-700 rounded-lg shadow divide-y divide-gray-200 dark:divide-gray-500"
    >
      <Expander.Button
        className="flex w-full px-2 py-2 sm:px-2"
        onClick={() => focus(ConfTypes.Cover, '')}
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-1 my-1 dark:text-gray-200">
            <span className="flex text-xl ">
              {formatMessage(messages.Cover)}
            </span>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-3 sm:p-6">
          <div className="pb-2 space-y-5">
            <div className="space-y-2">
              <Label text={formatMessage(messages.Name)} />
              <TextAreaInput
                debounce={{ timeout: 1000 }}
                rows={3}
                value={name ?? ''}
                onChange={(value) => set('options.name', value)}
              />
            </div>
            <div className="space-y-2">
              <TextAreaInput
                debounce={{ timeout: 1000 }}
                rows={3}
                value={title}
                onChange={(value) => set('cover.title', value)}
                onFocus={() => focus(ConfTypes.Cover, '')}
                maxLength={255}
                label={formatMessage(messages.Title)}
              />
            </div>
            <div className="space-y-2">
              <Label text={formatMessage(messages.Desc)} />
              <TextAreaInput
                debounce={{
                  timeout: 1000,
                  options: { leading: false, trailing: true },
                }}
                value={desc ?? ''}
                rows={6}
                onChange={(value) => set('.desc', value)}
                onFocus={() => focus(ConfTypes.Cover, '')}
              />
            </div>
            <div className="mb-1 space-y-2">
              <Label text={formatMessage(messages.CoverImage)} />
              <div className="max-w-sm h-52">
                <ImageInput
                  title={formatMessage(messages.CoverImage)}
                  src={
                    background ? `${widgetCoverImagePath}/${background}` : null
                  }
                  onClick={() => focus(ConfTypes.Cover, '')}
                  callback={(value) => set(`cover.background`, value)}
                  type="cover"
                  forceCrop
                />
              </div>
            </div>
            <div className="mb-1 space-y-2">
              <div className="max-w-sm">
                <ThemeInput
                  themeId={data?.theme_id || ''}
                  themeName={data?.theme?.name || ''}
                  isLoading={isLoading}
                  onSelect={(theme) => {
                    set(`options.theme`, theme);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(CoverForm);
