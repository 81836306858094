import React, { useContext, useEffect, useState } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl';
import {
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

import { Expander } from '../../../../Common/Form';
import { EditorCTX } from '../../../context';
import { ConfTypes } from '../../../enums';

import AnswerConf from './AnswerConf';
import CoverConf from './CoverConf';
import QuestionConf from './QuestionConf';
import ResultConf from './ResultConf';
import messages from '../messages';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type Props = WrappedComponentProps;
type TConfTypes = '' | 'cover' | 'question' | 'answer' | 'result';
const ConfigurationSection = (props: Props) => {
  const { formatMessage } = props.intl;

  const {
    data,
    activeQuestionIdx: qidx,
    focus,
    confType,
    confField,
  } = useContext(EditorCTX);
  const [conf, setConf] = useState<TConfTypes>('');

  const [t_id, setT_ID] = useState<string | number>('');
  const [t_title, setT_Title] = useState('');

  useEffect(() => {
    const args = confField.split('.');

    if (confType === ConfTypes.Cover) {
      setConf('cover');
      setT_ID('');
      setT_Title('');
      return;
    }
    if (confType === ConfTypes.Question) {
      if (args.slice(-2)[0] === 'questions') {
        setConf('question');
        setT_ID(Number(args.slice(-1)[0]) + 1);
        // questions.$
        setT_Title(data?.questions?.[args[1]]?.title);
        return;
      }
      if (args.slice(-2)[0] === 'answers') {
        setConf('answer');
        setT_ID(Number(args.slice(-1)[0]) + 1);
        // questions.$.answers.$
        setT_Title(data?.questions?.[args[1]]?.answers?.[args[3]]?.title);
        return;
      }
      return;
    }
    if (confType === ConfTypes.Result) {
      setConf('result');
      setT_ID(Number(args.slice(-1)[0]) + 1);
      // results.$
      setT_Title(data?.results?.[args[1]]?.title);
      return;
    }
  }, [data, confType, confField]);

  const _title = conf.charAt(0).toUpperCase() + conf.slice(1);

  function focusBackToQuestion(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    event.preventDefault();

    focus(ConfTypes.Question, `questions.${qidx}`, { save: false });
  }

  return (
    <Expander
      defaultOpen={true}
      as="div"
      className="overflow-hidden bg-white dark:bg-gray-700 rounded-lg shadow divide-y divide-gray-200 dark:divide-gray-200"
    >
      <Expander.Button className="flex w-full px-2 py-2 sm:px-2">
        {({ open }: { open: boolean }) => (
          <div className="flex justify-between w-full mx-1 my-1">
            <div className="flex flex-col w-3/4">
              <div className="flex flex-col">
                <h2 className="flex text-xl ">
                  <span className="text-poltio-blue ml-1">
                    {_title ? formatMessage(messages[_title]) : null}
                    {t_id ? ` - ${t_id}` : ''}
                  </span>
                </h2>
                <span className="ml-1 text-left dark:text-gray-200 truncate text-ellipsis">
                  {t_title ? t_title : ''}
                </span>
              </div>
              {conf === 'answer' ? (
                <div className="flex text-xs space-x-1">
                  <button
                    className="inline-flex align-middle space-x-1 hover:text-poltio-blue"
                    onClick={focusBackToQuestion}
                  >
                    <ChevronDoubleLeftIcon className="h-3.5 my-auto" />
                    <span>{formatMessage(messages['Question'])}</span>
                    <span>{Number(qidx) + 1}</span>
                  </button>
                  <span>{':'}</span>
                  <span className="truncate">
                    {data?.questions?.[qidx]?.title ?? ''}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex min-w-[1.25rem]">
              <ChevronRightIcon
                className={classNames(
                  'w-5 mt-1 dark:text-gray-200',
                  open ? 'rotate-90' : ''
                )}
              />
            </div>
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">
          {conf === 'cover' ? <CoverConf /> : null}
          {conf === 'question' ? <QuestionConf /> : null}
          {conf === 'answer' ? <AnswerConf /> : null}
          {conf === 'result' ? <ResultConf /> : null}
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(ConfigurationSection);
